.disclaimer__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--dim-gray);
}

.disclaimer__menu {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1em;
  margin-bottom: 0em;
}

.disclaimer__menu-options {
  display: flex;
  flex-direction: column;
  gap: 1em;
  font-size: 14px;
}

.disclaimer__menu-options a{
  color: white;
}

.disclaimer__menu-options a:hover{
  text-decoration: none;
}

.disclaimer__menu-text {
  font-size: 12px;
  margin-bottom: 2em;
  text-align: justify;
  width: 85vw;
}

.disclaimer__copyright {
  font-size: 12px;
  margin-bottom: 2em;
}

.disclaimer__copyright-hidden {
  display: none;
}

.disclaimer__button-as-link {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: bold;
  color: var(--dim-gray);
  text-decoration: none;
  cursor: pointer;
  outline: none;
  transition: color 0.3s;
}

@media screen and (max-width: 768px) {
  .disclaimer__menu-options a{
    color: var(--dim-gray);
    font-weight: bold;
  }
}

@media screen and (min-width: 768px) {
  .disclaimer__container {
    color: var(--white);
    font-family: sans-serif;
  }

  .disclaimer__button-as-link {
    color: var(--white);
    font-weight: normal;
  }

  .disclaimer__copyright {
    display: none;
    font-size: 11px;
  }

  .disclaimer__menu {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 70vw;
    margin-top: 0.5em;
  }

  .disclaimer__copyright-hidden {
    display: flex;
    order: 1;
    flex-basis: 43%;
    margin: 0;
  }

  .disclaimer__menu-options {
    order: 2;
    flex-basis: 53%;
    flex-direction: row;
    font-size: 9px;
    justify-content: end;
  }

  .disclaimer__menu-text {
    order: 3;
    flex-basis: 100%;
  }

  .disclaimer__menu-options > *:not(:last-child)::after {
    content: '|';
    margin-left: 8px;
    vertical-align: middle;
  }
}

@media screen and (min-width: 1024px) {
  .disclaimer__copyright {
    font-size: 14px;
    flex-basis: 38%;
  }

  .disclaimer__menu-options {
    font-size: 14px;
    flex-basis: 59%;
  }
}

@media screen and (min-width: 1200px) {
  .disclaimer__copyright {
    font-size: 16px;
  }

  .disclaimer__copyright-hidden {
    flex-basis: 45%;
  }

  .disclaimer__menu-options {
    font-size: 16px;
    flex-basis: 52%;
  }
}
