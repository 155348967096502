.custom__select-container {
  position: relative;
  margin-bottom: 15px;
}

.custom__select-container__no-margin {
  margin-bottom: 0;
}

.custom__select {
  border: 1px solid var(--gray);
  background-color: var(--white);
  color: var(--dark-gray);
  padding: 8px 16px;
  cursor: pointer;
  position: relative;
  font-size: 16px;
  height: 50px;
  margin-top: 10px;
  appearance: none;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.custom__select__margin-top {
  margin-top: 3px;
}

.custom__select-error {
  background-color: var(--light-pink);
}

.custom__select-arrow {
  content: '';
  position: absolute;
  top: 50%;
  right: 16px;
  width: 0;
  height: 0;
  margin-top: -2px;
  border-top: 5px solid var(--kelly-green);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.custom__select-dropdown {
  background-color: var(--white);
  list-style: none;
  border: 1px solid var(--light-gray);
  position: absolute;
  width: 100%;
  z-index: 10;
  margin-top: 1px;
  border-radius: 8px;
  padding-left: 0;
  color: var(--medium-gray);
  overflow-y: auto;
  max-height: 20vh;
}

.custom__select-dropdown li {
  padding: 8px 16px;
  cursor: pointer;
  box-sizing: border-box;
}

.custom__select-dropdown li:hover {
  background-color: #e5e5e5;
}

.custom__select-dropdown li + li {
  border-top: 1px solid #eee;
}
