
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* { /** place holder for font smoothing**/
    text-rendering: ;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ;
    -webkit-overflow-scrolling: touch;
    -webkit-scroll-behavior: smooth;
    scroll-behavior: smooth;
	-webkit-transition: 0.5s all;
	transition: 0.5s all;
	}

:root {
	--dark-gray: #333333;
	--light-gray: #ebebeb;
	--charcoal: #222222;
	--kelly-green: #74bc1e;
	--white: #ffffff;
	--black: #000000;
	--gray: #999999;
	--pale-gray: #f5f5f5;
	--soft-gray: #efefef;
	--medium-gray: #999999;
	--gray-lighter: bfbfbf;
	--dim-gray: #999;
	--gray-medium-light: #bbb;
	--dark-navy: #212529;
	--slate-gray: #495057;
	--red: #dc3545;
	--soft-red: #df3131;
	--charcoal-gray: #686868;
	--dove-gray: #424242;
	--light-pink: #ffdede;
	--soft-gray: #777777;
}

body,
html {
    font-family: "Montserrat", sans-serif !important;
    color: #000000;
	scroll-behavior: smooth;
	background-color: #FFFFFF;
	font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
	overflow-x: hidden;
}

img {
	max-width: 100%;
}

body p {
	margin: 0;
}

.topBar {
	background-color: #132b0b;
	padding: 50px;
}

.topBar p {
	color: #FFFFFF;
	line-height: 25px;
	margin: 0;
}

header.header img {
    max-width: 170px;
}

.heroArea {
	position: relative;
	background-image: url(../img/hero-img.jpg);
	background-size: cover;
	background-position: center center;
}
.heroArea:before {
	position: absolute;
	background-color: rgba(0, 0, 0, .65);
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	content: "";
}
.heroArea div.container {
	position: relative;
}
.heroArea div.container:after {
    position: absolute;
    background-image: url(../img/roundal-white.png);
    width: 200px;
    height: 200px;
    right: -20px;
    top: -20px;
    content: "";
    background-size: cover;
}
.heroArea h2 {
	font-size: 35px;
	line-height: 50px;
	font-weight: 600;
}
body .heroArea a.btnCta {
    text-align: center;
    text-decoration: none;
    display: block;
    max-width: 240px;
    margin: 1em auto 0;
}

.logosArea .logos-slider {
    /*display: flex;
    justify-content: center;*/
    width: 100%;
}
.logosArea .logos-slider .slick-list {
    overflow: visible !important;
}
.logosArea .logos-slider .slick-list {
    overflow: hidden !important;
}
.logosArea .logos-slider .slick-slide {
    float: left;
	margin: 0 10px;
	text-align: center;
}
.logosArea .logos-slider .slick-slide .sliderBox img,
.logosArea .logos-slider .sliderBox img {
    max-width: 80px;
}
.logosArea .logos-slider ul.slick-dots {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    list-style-type: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
}
.logosArea .logos-slider ul.slick-dots li {
    list-style-type: none;
    margin: 0 3px;
}
.logosArea .logos-slider ul.slick-dots li button {
    border: 1px solid #96d700;
    background-color: transparent;
    border-radius: 100px;
    font-size: 0;
    width: 10px;
    height: 10px;
    display: inline-block;
    padding: 0;
    vertical-align: top;
}
.logosArea .logos-slider ul.slick-dots li.slick-active button {
    border: 1px solid #96d700;
    background-color: #96d700;
}



.formArea {
	background-image: url(../img/map.png);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #132b0b;	
}
.formArea .stepImage {
	max-width: 400px;
	margin: 1em auto 0;
	width: 100%;
}
.formArea h2 {
    position: relative;
}

.formArea form ul,
.formArea form .main-form__step-container {
    margin: 1em auto 3em;
    padding: 0;
}
.formArea form .main-form__step-container {
	background: white;	
	padding: 3rem 4rem;
}
.formArea form ul li label,
.formArea form .main-form__step-container div .form-step__label {
    display: block;
    font-size: 20px;
    color: #74bc1e;
    font-weight: 500;
    margin: 0 0 1em;
    font-family: "Montserrat", sans-serif !important;
    text-align: center;
}
.heroArea a.btnCta,
.formArea form ul li input,
.formArea form .main-form__step-container div input {
    font-size: 16px;
    color: #333333;
    font-weight: 500;
    border: 1px solid black;
    background-color: #FFFFFF;
    width: 100%;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif !important;
}
.heroArea a.btnCta,
.formArea form ul li input[type="submit"],
.formArea form .main-form__step-container input[type="button"],
.formArea form .main-form__step-container input[type="submit"] {
	background-color: #74bc1e;
	border: 1px solid #74bc1e;
	margin: 1em 0 0;
	cursor: pointer;
	color: #FFFFFF;
}

.heroArea a.btnCta:hover,
.formArea form ul li input[type="submit"]:hover,
.formArea form .main-form__step-container input[type="button"]:hover,
.formArea form .main-form__step-container input[type="submit"]:hover {
	background-color: #96d700;
	border: 1px solid #96d700;
}

.contactArea {
	background-color: #F2F2F2;
}

footer.footer {
    background-color: #132b0b;
    font-size: 12px;
    color: #CCCCCC;
}
footer.footer p.disclaimerText {
    font-size: 10px;
    color: #CCCCCC;
}
footer.footer ul.socailLink {
	flex-wrap: wrap;
	margin: 1em 0 0;
}
footer.footer ul.socailLink li {
	margin: 0 5px;
	position: relative;
}
footer.footer ul.socailLink li + li:before {
	content: "";
	display: inline-block;
	-webkit-transform: translateX(-7px);
	transform: translateX(-7px);
	background-color: #96d700;
	width: 1px;
	height: 10px;
	vertical-align: middle;
	margin: 0 0 0 7px;
}
footer.footer ul.socailLink li a {
    color: #96d700;
    font-size: 12px;
}


@media(min-width: 992px){

	.heroArea div.container div.row div[class*=col] {
		padding: 3rem 0;
		margin: 3rem 0;
	}

}

@media(max-width: 1199px){
	.heroArea h2 {
	    font-size: 30px;
	}
	.heroArea div.container:after {
	    width: 160px;
	    height: 160px;
	    right: 0px;
	    top: 0px;
	}
}

@media(max-width:1024px){	
	.logosArea .logos-slider {
	    display: flex;
	    justify-content: center;
	    padding-bottom: 2.5em;
	    margin-bottom: 1em;
	}
}


@media(max-width:991px){	

	section.heroArea {
	    padding-bottom: 0 !important;
	}
	.heroArea h2 {
	    font-size: 25px;
	    line-height: 36px;
	}
	.heroArea div.container:after {
	    width: 140px;
	    height: 140px;
	    right: -10px;
	    top: -30px;
	}

}


@media(max-width: 767px){
	.contactArea .col-lg-4,
	.contactArea .col-lg-8 { 
		text-align: center;
		margin:0.5em 0;
	}
	.heroArea h2 {
	    font-size: 22px;
	    line-height: 35px;
	    text-align: center;
	    max-width: 400px;
	    margin: 1.5em auto 0;
	    position: relative;
	}
	.heroArea div.container:after {
	    width: 130px;
	    height: 130px;
	    right: -40px;
	    top: 0;
	    z-index: 9;
	}
	body .heroArea a.btnCta {
	    margin: auto !important;
	}
}

@media(max-width: 640px){

	.heroArea div.container:after {
	    width: 130px;
	    height: 130px;
	    right: 0px;
	    top: -20px;
	    z-index: 9;
	    left: 0;
	    margin: auto;
	}

	.topBar {
		padding: 35px 15px;
	}
	
	.topBar p {
		line-height: 20px;
		font-size: 13px;
	}

	.formArea h2{
		font-size: 20px;
		margin-bottom: 20px !important;
    margin-top: 0px !important;
	}

	.formArea.py-5{
		padding-top: 40px!important;
		padding-bottom: 0px !important;
	}

	.main-form__step-container.mt-5{
		margin-top: 30px!important;
	}
	.custom-submit__consent-text{
		font-size: 13px!important;
	}

	header.header.py-3 {
    padding: 10px 0px !important;
	}

	header.header img {
    max-width: 130px;
	}

	.main-form__step-container div input {
    margin-top: 6px !important;
	}

	.custom-submit__btn{
    padding: 12px!important;
	}

	.main-form__step-container div input{
		padding: 8px;
	}

	.custom-submit__container {
    margin-top: 9px!important;
	}

	.thank-you-alternative__box{
		padding: 15px!important;
	}

	.thank-you-alternative__main-content-title {
    font-size: 20px!important;
    margin-bottom: 5px!important;
	}

	.thank-you-alternative__main-content-text{
		font-size: 1rem!important;
		margin-bottom: 10px!important;
	}
	.formArea form .main-form__step-container {
		padding: 1.3rem 1.8rem;
	}
	.form-step{
		margin-top: 11px!important;
	}
	.form-step__label{
		margin: 0 0 0.5rem!important;
	}
	.formArea h2{
		margin-bottom: 10px!important;
		font-size: 18px;
	}
}

@media(max-width: 425px){

	footer.footer ul.socailLink li {
		margin: 5px 0;
		position: relative;
		width: 100%;
	}
	footer.footer ul.socailLink li:before,
	footer.footer ul.socailLink li + li:before {
		content: "";
		display: inline-block;
		-webkit-transform: translateX(-0px);
		transform: translateX(-0px);
		background-color: rgba(150, 215, 0, 0.15);
		width: 100%;
		height: 1px;
		vertical-align: middle;
		margin: 0 0 15px;
		bottom: -20px;
		left: 0;
		position: absolute;
	}

}