.page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main-content {
  width: 100vw;
  height: 100vh;
}

.disclaimer {
  margin-top: 0em;
  padding: 1em 0 0;
}

#pixelrgr_pageview_div {
  display: none;
}

.brand-logos{
  margin: 0 auto!important;
}

@media (min-width: 768px) {
  .disclaimer {
    background: #132b0b;
    color: var(--white);
  }
}
