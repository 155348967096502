@font-face {
  font-family: 'TG';
  src: url('../fonts/TradeGothicNextLTPro-HvCn.eot');
  src: url('../fonts/TradeGothicNextLTPro-HvCn.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/TradeGothicNextLTPro-HvCn.woff2') format('woff2'),
    url('../fonts/TradeGothicNextLTPro-HvCn.woff') format('woff'),
    url('../fonts/TradeGothicNextLTPro-HvCn.ttf') format('truetype'),
    url('../fonts/TradeGothicNextLTPro-HvCn.svg#TradeGothicNextLTPro-HvCn')
      format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'TG';
  src: url('../fonts/TradeGothicNextLTPro-Bd.eot');
  src: url('../fonts/TradeGothicNextLTPro-Bd.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/TradeGothicNextLTPro-Bd.woff2') format('woff2'),
    url('../fonts/TradeGothicNextLTPro-Bd.woff') format('woff'),
    url('../fonts/TradeGothicNextLTPro-Bd.ttf') format('truetype'),
    url('../fonts/TradeGothicNextLTPro-Bd.svg#TradeGothicNextLTPro-Bd')
      format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'TG';
  src: url('../fonts/TradeGothicNextLTPro-Rg.eot');
  src: url('../fonts/TradeGothicNextLTPro-Rg.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/TradeGothicNextLTPro-Rg.woff2') format('woff2'),
    url('../fonts/TradeGothicNextLTPro-Rg.woff') format('woff'),
    url('../fonts/TradeGothicNextLTPro-Rg.ttf') format('truetype'),
    url('../fonts/TradeGothicNextLTPro-Rg.svg#TradeGothicNextLTPro-Rg')
      format('svg');
  font-weight: normal;
  font-style: normal;
}
