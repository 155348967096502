.form-step__label {
  font-size: 15px;
  font-weight: normal;
}
.form-step__label.green-label{
    color: #74bc1e!important;
}

.form-step__label-last {
  margin-top: 30px;
}

.form-step__input-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 3px;
}

.form-step__input {
  border: 1px solid var(--gray);
  cursor: pointer;
  border-radius: 8px;
  font-size: 16px;
  letter-spacing: 0.26px;
  text-decoration: none;
  padding: 17px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 3px;
}

.form-step__input-radio-wrapper {
  display: flex;
  align-items: center;
}

.form-step__input-radio-wrapper input[type='radio'] {
  background-color: var(--white);
  border: 1px solid var(--kelly-green);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  outline: none;
  margin-right: 16px;
  cursor: pointer;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: center;
}

.form-step__input-radio-wrapper input[type='radio']:checked {
  background-color: var(--white);
  border-color: var(--kelly-green);
  background-image: radial-gradient(
    circle,
    var(--kelly-green) 50%,
    transparent 51%
  );
}

.form-step__input-radio-wrapper input[type='radio']:checked::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  background-color: var(--kelly-green);
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.form-step__btn {
  text-decoration: none;
  font-size: 18px;
  text-align: center;
  padding: 13px 94px 13px;
  border-radius: 8px;
  color: var(--white);
  background-color: #74bc1e;
  border: 1px solid #74bc1e;
  font-weight: 900;
  cursor: pointer;
}
.form-step__btn:hover {
  text-decoration: none;
  font-size: 18px;
  text-align: center;
  padding: 13px 94px 13px;
  border-radius: 8px;
  color: var(--white);
  background-color: #96d700;
  border: 1px solid #96d700;
  font-weight: 900;
  cursor: pointer;
}
.form-step__btn-back {
  text-decoration: none;
  font-size: 18px;
  text-align: center;
  padding: 13px 94px 13px;
  border-radius: 8px;
  color: var(--gray)!important;
  background-color: transparent!important;
  border: none!important;
  font-weight: 900;
  cursor: pointer;
  margin-top: 5px;
}

.form-step {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 15px;
  font-family: sans-serif;
}

.form-step__input-group {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.form-step__input-group__no-margin {
  margin-top: 4px;
}

.form-step__input-no-background {
  background: none;
}

.form-step__error-box {
  color: var(--red);
  text-align: center;
  padding: 0 0.7em;
}

.form-step__error {
  border: 1px solid var(--red);
}

.form-step__error-message {
  color: var(--red);
  font-size: 12px;
  margin-top: 4px;
}

.form-step__error-text-label {
  color: var(--soft-red);
  margin-top: 0.8em;
  text-align: justify;
}

.form-step__input-error {
  background: var(--white);
  border: solid 2px red!important;
  outline: none;
}
.hintArea {
    background: #f7f7f3 url(../../../assets/img/light-bulb-icon.svg) 13px 13px no-repeat;
    border-radius: 6px;
    font-size: 14px;
    line-height: 20px;
    margin: 2em auto 0;
    max-width: 450px;
    padding: 15px 25px 15px 60px;
    position: relative;
  width: 100%;
  text-align: left;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.hintArea .hint__title {
    font-weight: 700;
    margin-bottom: 0 0 2px;
}
@media (min-width: 768px) {
  .form-step__label {
    color:#ffffff;
    font-size: 24px;
  }

  .form-step__input-radio-wrapper label {
    font-size: 18px;
  }

  .form-step__input-radio-wrapper input[type='radio'] {
    width: 26px;
    height: 26px;
  }

  .form-step__input-radio-wrapper input[type='radio']:checked::before {
    width: 14px;
    height: 14px;
  }

  .form-step__input-group {
    display: grid;
    grid-template-columns: minmax(auto, 1fr) minmax(0, 1fr);
    column-gap: 20px;
  }

  .form-step3 > .form-step > .form-step__input-group > input {
    box-sizing: border-box;
  }

  .form-step__error-box {
    width: 350px;
    padding: 0px 40px 0px 40px;
    font-size: 16px;
  }

  .form-step__error-message {
    font-size: 18px;
  }
}
