.thank-you-alternative__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 900;
}

.thank-you-alternative-lenders__pixel {
  display: none;
}

.thank-you-alternative__box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 10px;
  width: 75vw;
  transition: all 0.3s ease;
}

.thank-you-alternative__box:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.thank-you-alternative__logo-container {
  background: var(--dark-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  box-sizing: border-box;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.thank-you-alternative__logo {
  width: 200px;
}

.thank-you-alternative__main-content {
  margin-top: 10px;
}

.thank-you-alternative__main-content-title {
  font-size: 2rem;
  color: var(--dove-gray);
  text-align: center;
  margin-bottom: 1rem;
}

.thank-you-alternative__main-content-text {
  font-size: 1.2rem;
  text-align: justify;
  color: var(--dove-gray);
  margin-bottom: 2rem;
}

.thank-you-alternative__phone-link {
  color: var(--kelly-green);
}

.thank-you-alternative__main-content-btn {
  background-color: var(--kelly-green);
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.thank-you-alternative__main-content-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  color: white;
  text-decoration: none;
}

@media (min-width: 768px) {
  .thank-you-alternative__container {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 5em;
  }

  .thank-you-alternative__box {
    background-color: var(--white);
  }

  .thank-you-alternative__main-box {
    width: 93%;
    min-height: 200px;
  }

  .thank-you-alternative__phone-box,
  .thank-you-alternative__home-box {
    width: 42%;
    min-height: 280px;
  }

  .thank-you-alternative__main-content-title {
    font-size: 2.5rem;
  }

  .thank-you-alternative__main-content-text {
    font-size: 1.6rem;
    text-align: center;
  }
}
